import { Fragment } from "react";
import Typography from "@mui/material/Typography";

const useCases = [
  {
    title: "Distributed Skin Lesion Analysis across Decentralized Data Sources",
    description:
      "We applied our architecture to Distributed Skin Lesion Analysis across Three Decentralised Data Sources. We performed a straightforward statistics study and a heavy-weight machine learning task, where we trained a classifier decentrally. ",
  },
  {
    title: "Diagnosen-Koinzidenz für Mukoviszidose und Entbindung",
    description:
      "We applied our architecture to an analysis of diagnose-coincidence for mucoviscidosis and childbirth. This data use case was under the umbrella of our CORD project, where we applied our distributed analytics infrastructure.",
  },
];

export default function DataUseCases() {
  return (
    <>
      <Typography variant="h4" fontWeight="bold" mt={10} mb={5}>
        Data Use Cases
      </Typography>
      {useCases.map(({ title, description }) => (
        <Fragment key={`data-use-case-${title}`}>
          <Typography variant="h5" fontWeight="bold" mb={3}>
            {title}
          </Typography>
          <Typography variant="h6" mb={7}>
            {description}
          </Typography>
        </Fragment>
      ))}
    </>
  );
}
