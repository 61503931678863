import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Footer = () => {
  const year = new Date().getFullYear();
  const affiliatedInstitutions = [
    {
      name: "University of Cologne - Biomedical Informatics",
      addr: (
        <>
          Zülpicher Str. 58
          <br />
          50674 Köln, Germany
        </>
      ),
    },
    {
      name: "RWTH Aachen University - The Chair of Computer Science 5",
      addr: (
        <>
          Ahornstr. 55
          <br />
          52056 Aachen, Germany
        </>
      ),
    },
    {
      name: "Fraunhofer Institute for Applied Information Techniques (FIT)",
      addr: (
        <>
          Schloss Birlinghoven
          <br />
          53757 Sankt Augustin, Germany
        </>
      ),
    },
    {
      name: "University Medicine Leipzig - Institute for Medical Data Science",
      addr: (
        <>
          Härtelstr. 16-18
          <br />
          04107 Leipzig, Germany
        </>
      ),
    },
  ];

  return (
    <Box px={5} pt={10} pb={3} mt={10} bgcolor="primary.main" color="white">
      <Grid container spacing={3}>
        <Grid item container alignItems="center" md={6}>
          <Grid item xs={6}>
            <a
              href="https://www.go-fair.org/implementation-networks/overview/personal-health-train/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./assets/logo-pht.png"
                alt="PHT Logo"
                width="100%"
                style={{ borderRadius: 20 }}
              />
            </a>
            <Typography textAlign="center" fontWeight="bold">
              PADME is a PHT implementation
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={6}>
            <img src="./assets/logo.png" alt="PADME Logo" width="75%" />
          </Grid>
        </Grid>
        <Grid item container spacing={4} md={6}>
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="h6" mb={3}>
              Our affiliations are:
            </Typography>
          </Grid>
          {affiliatedInstitutions.map((inst) => (
            <Grid item xs={6} key={inst.name}>
              <Typography fontWeight="bold" mb={1}>
                {inst.name}
              </Typography>
              <Typography>
                <strong>Address:</strong>
              </Typography>
              <Typography>{inst.addr}</Typography>
            </Grid>
          ))}
          <Grid item container xs={12}>
            <Typography fontWeight="bold" mr={1}>
              Contact us via:
            </Typography>
            <Link
              href="mailto:pht@dbis.rwth-aachen.de"
              underline="hover"
              color="white"
            >
              pht@dbis.rwth-aachen.de
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Typography textAlign="center" variant="body2" pt={10}>
        © Copyright 2021-{year}, PHT Working Group (PADME)
        <br />
        pht@dbis.rwth-aachen.de
      </Typography>
    </Box>
  );
};

export default Footer;
