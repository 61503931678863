import { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { RenderLink } from "../../utils";

const journals = [
  {
    year: 2024,
    publications: [
      {
        authors:
          "Sascha Welten, Marius de Arruda Botelho Herr, Lars Hempel, David Hieber, Peter Placzek, Michael Graf, Sven Weber, Laurenz Neumann, Maximilian Jugl, Liam Tirpitz, Karl Kindermann, Sandra Geisler, Luiz Olavo Bonino da Silva Santos, Stefan Decker, Nico Pfeifer, Oliver Kohlbacher, Toralf Kirsten",
        title:
          "A study on interoperability between two Personal Health Train infrastructures in leukodystrophy data analysis",
        journal: "Sci Data 11, 663",
        doi: "10.1038/s41597-024-03450-6",
        link: "https://doi.org/10.1038/s41597-024-03450-6",
      },
      {
        authors:
          "Sascha Welten, Sven Weber, Adrian Holt, Oya Beyan, Stefan Decker",
        title:
          "Will it run?—A proof of concept for smoke testing decentralized data analytics experiments",
        journal: "Frontiers in Medicine",
        doi: "10.3389/fmed.2023.1305415",
        link: "https://doi.org/10.3389/fmed.2023.1305415",
      },
    ],
  },
  {
    year: 2023,
    publications: [
      {
        authors:
          "Yongli Mou, Feifei Li, Sven Weber, Sabith Haneef, Hans Meine, Liliana Caldeira, Mehrshad Jaberansary, Sascha Welten, Yeliz Yediel Ucer, Guido Prause, Stefan Decker, Oya Beyan, Toralf Kirsten",
        title:
          "Distributed Privacy-Preserving Data Analysis in NFDI4Health With the Personal Health Train",
        journal:
          "Proceedings of the Conference on Research Data Infrastructure",
        doi: "10.52825/cordi.v1i.282",
        link: "https://www.tib-op.org/ojs/index.php/CoRDI/article/view/282",
      },
      {
        authors:
          "Jiahui Geng, Yongli Mou, Qing Li, Feifei Li, Oya Beyan, Stefan Decker, Chunming Rong",
        title:
          "Improved Gradient Inversion Attacks and Defenses in Federated Learning",
        journal: "IEEE Transactions on Big Data",
        doi: "10.1109/TBDATA.2023.3239116",
        link: "https://doi.ieeecomputersociety.org/10.1109/TBDATA.2023.3239116",
      },
      {
        authors:
          "Kais Tahar, Tamara Martin, Yongli Mou, Raphael Verbuecheln, Holm Graessner, Dagmar Krefting",
        title:
          "Rare Diseases in Hospital Information Systems-An Interoperable Methodology for Distributed Data Quality Assessments",
        journal: "Methods of Information in Medicine AAM",
        doi: "10.1055/a-2006-1018",
        link: "http://dx.doi.org/10.1055/a-2006-1018",
      },
    ],
  },
  {
    year: 2022,
    publications: [
      {
        authors:
          "Geng, Jiahui, Ali Akbar Rehman, Yongli Mou, Stefan Decker, and Chunming Rong",
        title: "Blockchain-based Cross-organizational Workflow Platform",
        journal:
          "2022 IEEE International Conference on Cloud Computing Technology and Science (CloudCom), Bangkok, Thailand",
        doi: "10.1109/CloudCom55334.2022.00018",
        link: "http://dx.doi.org/10.1109/CloudCom55334.2022.00018",
      },
      {
        authors:
          "Sascha Martin Welten, Adrian Holt, Julian Hofmann, Lennart Schelter, Elena-Maria Klopries, Thomas Wintgens, Stefan Josef Decker",
        title:
          "Synthetic rainfall data generator development through decentralised model training",
        journal: "Journal of hydrology",
        doi: "10.1016/j.jhydrol.2022.128210",
        link: "https://doi.org/10.1016/j.jhydrol.2022.128210",
      },
      {
        authors:
          "Sascha Martin Welten, Lars Hempel, Masoud Abedi, Yongli Mou, Mehrshad Jaberansary, Laurenz Neumann, Sven Weber, Kais Tahar, Yeliz Ucer Yediel, Matthias Löbe, Stefan Josef Decker, Oya Deniz Beyan, Toralf Kirsten",
        title:
          "Multi-Institutional Breast Cancer Detection Using a Secure On-Boarding Service for Distributed Analytics",
        journal: "Applied Sciences",
        doi: "10.3390/app12094336",
        link: "https://doi.org/10.3390/app12094336",
      },
      {
        authors:
          "Sascha Welten, Yongli Mou, Laurenz Neumann, Mehrshad Jaberansary, Yeliz Ucer Yediel, Toralf Kirsten, Stefan Decker, Oya Beyan",
        title:
          "Privacy-Preserving Distributed Analytics Platform for Health Care Data",
        journal: "Methods of Information in Medicine",
        doi: "10.1055/s-0041-1740564",
        link: "https://doi.org/10.1055/s-0041-1740564",
      },
    ],
  },
  {
    year: 2021,
    publications: [
      {
        authors:
          "Sascha Welten, Laurenz Neumann, Yeliz Ucer Yediel, Luiz Olavo Bonino da Silva Santos, Stefan Decker, Oya Beyan",
        title: "DAMS: A Distributed Analytics Metadata Schema",
        journal: "Data Intelligence",
        doi: "10.1162/dint_a_00100",
        link: "https://doi.org/10.1162/dint_a_00100",
      },
      {
        authors:
          "Hochheiser H, Jing X, Garcia EA, Ayvaz S, Sahay R, Dumontier M, Banda JM, Beyan O, Brochhausen M, Draper E, Habiel S, Hassanzadeh O, Herrero-Zazo M, Hocum B, Horn J, LeBaron B, Malone DC, Nytrø Ø, Reese T, Romagnoli K, Schneider J, Zhang L(Y), Boyce RD",
        title:
          "A Minimal Information Model for Potential Drug-Drug Interactions",
        journal: "Frontiers in Pharmacology",
        doi: "10.3389/fphar.2020.608068",
        link: "https://doi.org/10.3389/fphar.2020.608068",
      },
      {
        authors:
          "Karim MdR, Jiao J, Döhmen T, Cochez M, Beyan O, Rebholz-Schuhmann D, Decker S",
        title:
          "DeepKneeExplainer: Explainable Knee Osteoarthritis Diagnosis from Radiographs and Magnetic Resonance Imaging",
        journal: "IEEE Access",
        doi: "10.1109/ACCESS.2021.3062493",
        link: "https://doi.org/10.1109/ACCESS.2021.3062493",
      },
    ],
  },
  {
    year: 2020,
    publications: [
      {
        authors:
          "Gleim LC, Karim MdR, Zimmermann L, Kohlbacher O, Stenzhorn H, Decker S, Beyan O",
        title:
          "Enabling Ad-hoc Reuse of Private Data Repositories through Schema Extraction",
        journal: "Journal of Biomedical Semantics. 11, 6",
        doi: "10.1186/s13326-020-00223-z",
        link: "https://doi.org/10.1186/s13326-020-00223-z",
      },
      {
        authors:
          "Karim MdR, Cochez M, Zappa A, Sahay R, Rebholz-Schuhmann D, Beyan O, Decker S",
        title:
          "Convolutional Embedded Networks for Population Scale Clustering and Bio-ancestry Inferencing",
        journal:
          "IEEE/ACM Transactions on Computational Biology and Bioinformatics",
        doi: "10.1109/tcbb.2020.2994649",
        link: "https://doi.org/10.1109/tcbb.2020.2994649",
      },
      {
        authors:
          "Bukowski M, Farkas R, Beyan O, Moll L, Hahn H, Kiessling F, Schmitz-Rode T",
        title:
          "Implementation of eHealth and AI integrated diagnostics with multidisciplinary digitized data: are we ready from an international perspective?",
        journal: "European Radiology",
        doi: "10.1007/s00330-020-06874-x",
        link: "https://doi.org/10.1007/s00330-020-06874-x",
      },
      {
        authors:
          "Karim MdR, Beyan O, Zappa A, Costa IG, Rebholz-Schuhmann D, Cochez M, Decker S",
        title: "Deep Learning-based Clustering Approaches for Bioinformatics",
        journal: "Briefings in Bioinformatics",
        doi: "10.1093/bib/bbz170",
        link: "https://doi.org/10.1093/bib/bbz170",
      },
      {
        authors:
          "McQuilton P, Batista D, Beyan O, Granell R, Coles S, Izzo M, Lister AL, Pergl R, Rocca-Serra P, Schaap B, Shanahan H, Thurston M, Sansone SA",
        title:
          "Helping the Consumers and Producers of Standards, Repositories and Policies to Enable FAIR Data",
        journal: "Data Intelligence",
        doi: "10.1162/dint_a_00037",
        link: "https://doi.org/10.1162/dint_a_00037",
      },
      {
        authors:
          "Beyan O, Choudhury A, van Soest J, Kohlbacher O, Zimmermann L, Stenzhorn H, Karim MdR, Dumontier M, Decker S, Bonino da Silva Santos LO, Dekker A",
        title:
          "Distributed Analytics on Sensitive Medical Data: The Personal Health Train",
        journal: "Data Intelligence",
        doi: "10.1162/dint_a_00032",
        link: "https://doi.org/10.1162/dint_a_00032",
      },
    ],
  },
];

export default function Journals() {
  return (
    <>
      <Typography variant="h4" fontWeight="bold" mt={10} mb={5}>
        Journals
      </Typography>
      {journals.map(({ year, publications }) => (
        <Fragment key={`journal-${year}`}>
          <Typography variant="h5" fontWeight="bold" mb={3}>
            {year}
          </Typography>
          {publications.map(({ authors, title, journal, doi, link }, idx) => (
            <Typography
              variant="h6"
              key={title}
              mb={publications.length - 1 === idx ? 7 : 3}
            >
              {authors}; {title}. In <em>{journal}</em> ({year}); DOI:{" "}
              <RenderLink href={link} text={doi} />
            </Typography>
          ))}
        </Fragment>
      ))}
    </>
  );
}
