import Container from "@mui/material/Container";
import JournalsFragment from "./Journals";
import ConferenceFragment from "./Conferences";
import DataUseCaseFragment from "./DataUseCases";
import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";

const PublicationsPage = () => {

  return (
    <Container>
      <ScrollToTop />
      <Title text="Publications" mb={5} />
      <JournalsFragment />
      <ConferenceFragment />
      <DataUseCaseFragment />
    </Container>
  );
};

export default PublicationsPage;
